export const paymentsQueryKeys = {
  merchant: 'paymentsMerchant',
  merchantLogo: 'paymentsMerchantLogo',
  paymentsUrl: 'paymentsUrl',
  invoiceExport: 'invoiceExport',
  allRefunds: 'allRefunds',
  stripeExpressPublicKey: 'stripeExpressPublicKey',
  createPaymentRequest: 'createPaymentRequest',
  manualTemplates: 'manualTemplates',
  bnplTtpLink: 'bnplTtpLink',
  createTerminal: 'createTerminal',
  autoConnectReader: 'autoConnectReader',
};

export const getInterScreenQueryOptions = (staleTime?: number, retry: boolean | number = false) => ({
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  ...(!retry ? { retry: false } : typeof retry === 'number' ? { retry } : {}),
  ...(staleTime ? { staleTime } : {}),
});
