import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { invalidateDiscoverReaderQuery, useDiscoverReaderQuery } from '@frontend/payments-collection-flow';
import { useMerchant } from '@frontend/payments-hooks';
import { RegisterTerminals } from '@frontend/payments-register-terminal';
import { theme } from '@frontend/theme';
import { Table, PlusIcon, useModalControl, Text } from '@frontend/design-system';
import { useCanDoAction } from '../../../../hooks';
import { generateColumns } from './generate-columns';

const styles = {
  plusIcon: css`
    margin-right: ${theme.spacing(1)};
    margin-left: ${theme.spacing(-1)};
  `,
};

export const TerminalSettings = () => {
  const { t } = useTranslation('payments');
  const { stripeLocationId, locationId, paymentsUrl } = useMerchant();
  const { modalProps, openModal, closeModal } = useModalControl();
  const { isPaymentsBillingManager } = useCanDoAction();
  const query = useDiscoverReaderQuery({ stripeLocationId, locationId, paymentsUrl: paymentsUrl ?? undefined });
  const { invalidateQuery } = invalidateDiscoverReaderQuery(locationId || '');

  if (!stripeLocationId) return <Text>{t('Card Payments are not enabled for this location.')}</Text>;
  return (
    <>
      <Table
        data={query.data ?? ([] as NonNullable<typeof query.data>)}
        isLoading={query.isLoading}
        colConfig={generateColumns()}
        isPaginated
        clientPaginationConfig={{
          defaultRowsPerPage: 5,
          rowsPerPageOptions: [5, 10, 15],
        }}
        tableActions={[
          {
            label: (
              <>
                <PlusIcon css={styles.plusIcon} />
                {t('Register Terminal')}
              </>
            ),
            type: 'button',
            variant: 'secondary',
            size: 'small',
            title: isPaymentsBillingManager
              ? t('Register Terminal')
              : t('Only payments billing managers can register a terminal.'),
            disabled: !isPaymentsBillingManager,
            onClick: () => openModal(),
          },
        ]}
      />
      <RegisterTerminals modalProps={modalProps} closeModal={closeModal} onTerminalRegistered={invalidateQuery} />
    </>
  );
};
