import { ReactNode } from 'react';
import { SerializedStyles, css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { Heading } from '@frontend/design-system';
import * as settingStyles from '../styles';

interface PaymentsCardProps {
  children: ReactNode;
  title?: string;
  style?: SerializedStyles[] | SerializedStyles;
}

export const paymentSettingsCardStyles = {
  card: css`
    margin-bottom: ${theme.spacing(2)};
    border: 1px solid ${theme.colors.neutral20};
    background: ${theme.colors.white};
    border-radius: ${theme.borderRadius.medium};
    box-shadow: ${theme.shadows.light};
    padding: ${theme.spacing(2, 2)};
    position: relative;
  `,
  title: css`
    margin-bottom: ${theme.spacing(3)};
  `,
};

export const PaymentsCard = ({ children, title, style }: PaymentsCardProps) => {
  const cssArray = Array.isArray(style) ? style : style ? [style] : [];
  return (
    <div css={[paymentSettingsCardStyles.card, ...cssArray]}>
      {title && (
        <Heading level={3} css={paymentSettingsCardStyles.title}>
          {title}
        </Heading>
      )}
      {children}
    </div>
  );
};

export const PaymentSettingsCard = ({ children, title, style }: PaymentsCardProps) => {
  const cssArray = Array.isArray(style) ? style : style ? [style] : [];
  return (
    <PaymentsCard title={title} style={[settingStyles.groupMaxWidth, ...cssArray]}>
      {children}
    </PaymentsCard>
  );
};
